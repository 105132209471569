import React from "react";
import { Link } from "react-router-dom";

export const ConnectionPage = () => {
  return (
    <>
      <h1>Windows VPN Connection</h1>
      See the{" "}
      <Link to="/vpn-connection-prerequisites.html">
        VPN Connection Prerequisites
      </Link>{" "}
      before attempting to connect.
      <h2>Checking Posture Status</h2>
      Before following the Connection Steps below,{" "}
      <b>
        please verify that your posture meets the proper requirements. If your
        posture is not up to standard, your connection will be unsuccessful.
        View your posture status in the{" "}
        <Link to="/windows/troubleshooting.html">troubleshooting page.</Link>{" "}
      </b>
      <h2>Connection Steps</h2>
      <ol>
        <li>
          {" "}
          Navigate to the provided URL from your Amazon contact. It will look
          something like{" "}
          <b>https://self-service.clientvpn.amazonaws.com/xxxxxxxx</b>
        </li>
        <li>
          You will be directed to authenticate with Idprism if you have not
          recently authenticated. Enter your IdPrism email address.
        </li>
        <img
          src={require("../../../images/idprism-enter-email-address.png")}
          alt="Enter your email address in IdPrism"
        />
        <li>
          Enter your Idprism password (this is the password you setup when you
          registered with IdPrism)
        </li>
        <img
          src={require("../../../images/idprism-enter-password.png")}
          alt="Enter your password in IdPrism"
        />
        <li>
          Enter the verification code sent via SMS to the phone number in your
          IdPrism account
        </li>
        <img
          src={require("../../../images/idprism-verification-code.png")}
          alt="Enter verification code"
        />
        <li>
          You will then be redirected to the page to download the OPVN file
        </li>
        <img
          src={require("../../../images/ovpn-download-page.png")}
          alt="OVPN file download page"
        />
        <li>Open the AWS VPN Client</li>
        <img
          src={require("../../../images/windows/windows-aws-vpn-client-open.png")}
          alt="Open AWS VPN Client"
        />
        <li>
          Click <code>File &gt Manage Profiles</code>
        </li>
        <img
          src={require("../../../images/windows/windows-aws-vpn-client-create-profile.png")}
          alt="Create Profile"
        />
        <li>
          Click <code>Add Profile</code>
        </li>
        <img
          src={require("../../../images/windows/windows-aws-vpn-client-add-profile.png")}
          alt="Add The Profile"
        />
        <li>
          Set the <code>Display Name</code>, and provide the path to the OVPN
          file
        </li>
        <img
          src={require("../../../images/windows/windows-aws-vpn-client-profile-details.png")}
          alt="Enter Profile Details"
        />
        <li>
          Click <code>Done</code>
        </li>
        <img
          src={require("../../../images/windows/windows-aws-vpn-client-profile-added.png")}
          alt="Click Done"
        />
        <li>
          Select the newly created profile and click <code>Connect</code>
        </li>
        <img
          src={require("../../../images/windows/windows-aws-vpn-client-profile-selected.png")}
          alt="Select Newly Created Profile"
        />
        The AWS VPN Client will now open your default browser.
        <li>
          If prompted, follow the IdPrism login flow,{" "}
          <b>same steps as above.</b>
        </li>
        <li>
          You will then be shown a table with all the networks you currently
          have active grants to. Ensure the site you are connecting to is in
          that table.
          <br />
          <br />
          <b>NOTE: </b> This table does not mean you have access to all of those
          sites and networks at the same time. It is just showing which sites
          you have active access to. You may only connect to one site at a time
          in the VPN client
        </li>
        <img
          src={require("../../../images/user-access-table.png")}
          alt="Access Table"
        />
        <li>The connection should successfully establish</li>
        <img
          src={require("../../../images/aws-vpn-client-login-successful.png")}
          alt="Login Successful"
        />
      </ol>
      <p>You should now be connected to the VPN.</p>
      <p>
        For issues with connecting, see&nbsp;
        <Link to="/windows/troubleshooting.html">Windows Troubleshooting</Link>.
      </p>
    </>
  );
};
